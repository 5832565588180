import React, { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { selectPublications } from '../../store/selectors';
import { getPublications } from '../../store/actions';
import styles from './business-unit-dropdown.module.scss';

interface IBusinessUnitDropdownProperties {
  selectedValue: string | null;
  onChange: (event: { value: string | null }) => void;
}

const BusinessUnitDropdown: React.FC<IBusinessUnitDropdownProperties> = ({ selectedValue, onChange }) => {
  const dispatch = useDispatch();
  const publications = useSelector(selectPublications);
  const [businessUnits, setBusinessUnits] = useState<string[]>([]);

  useEffect(() => {
    if (publications.length === 0) {
      dispatch(getPublications());
    }
  }, [dispatch]);

  useEffect(() => {
    if (publications.length > 0) {
      const uniqueBusinessUnits = publications
        .map((publication) => publication.businessUnit)
        .filter((value, index, self) => value && self.indexOf(value) === index);
      setBusinessUnits(uniqueBusinessUnits);
    }
  }, [publications]);

  return (
    <Dropdown
      id="businessUnitDropdown"
      value={selectedValue}
      options={businessUnits.map((b) => ({ label: b, value: b }))}
      onChange={onChange}
      placeholder={'Select a Business Unit'}
      className={styles.businessUnitDropdown}
      showClear
    />
  );
};

export default BusinessUnitDropdown;
