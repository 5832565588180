/* eslint-disable unicorn/no-null */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken, selectSalesSteerings } from '../../../store/selectors';
import { getSalesSteerings } from '../../../store/actions';
import { formatDateTime, formatDate, makeDate } from '../../../utilities/formatting';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { PAGINATOR_ROWS, PAGINATOR_ROWS_PER_PAGE, PAGINATOR_TEMPLATE } from '../../constants/paginator-settings';
import dateFilterTemplate from '../../shared/date-filter-template';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import publicationFilterTemplate from '../../shared/publication-filter-template';
import { ISalesSteering, selectPublications } from '../../../store/actions/sales-configuration';
import SalesSteeringRemarkList from '../sales-steering-remarks-list/sales-steering-remarks-list';
import BusinessUnitDropdown from '../../shared/business-unit-dropdown';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialFilters: Record<string, any> = {
  accommodationCode: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  publicationCode: { value: null, matchMode: FilterMatchMode.IN },
  startDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
  endDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
  contractId: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
  transportHubTypeName: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  transportHubs: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  createdDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
  modifiedDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
};

function SalesSteeringList() {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState(initialFilters);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [expandedRows, setExpandedRows] = useState<any>(null);
  const salesSteerings = useSelector(selectSalesSteerings).map((salesSteering) => ({
    ...salesSteering,
    startDate: makeDate(salesSteering.startDate),
    endDate: makeDate(salesSteering.endDate),
    createdDate: makeDate(salesSteering.createdDate),
    modifiedDate: makeDate(salesSteering.modifiedDate),
  }));
  const publications = useSelector(selectPublications).filter((publication) => publication.businessUnit !== null);
  const accessToken = useSelector(selectAccessToken);

  const remarksExpansionTemplate = (data: ISalesSteering) => {
    return (
      expandedRows !== undefined && (
        <div>
          <SalesSteeringRemarkList key={data.id} salesSteeringId={data.id} />
        </div>
      )
    );
  };

  useEffect(() => {
    if (salesSteerings.length === 0 && accessToken) {
      dispatch(getSalesSteerings());
    }
  }, [accessToken]);

  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<string | null>(null);

  const filterByBusinessUnit = (event: { value: string | null }) => {
    const selectedValue = event.value;
    if (selectedValue === null || selectedValue === undefined) {
      setFilters((previousFilters) => ({
        ...previousFilters,
        publicationCode: { ...previousFilters.publicationCode, value: null },
      }));
      setSelectedBusinessUnit(null);
    } else {
      setSelectedBusinessUnit(selectedValue);
      const filteredPublicationCodes = publications
        .filter((publication) => publication.businessUnit === selectedValue)
        .map((publication) => publication.publicationCode);

      setFilters((previousFilters) => ({
        ...previousFilters,
        publicationCode: { ...previousFilters.publicationCode, value: filteredPublicationCodes },
      }));
    }
  };

  return (
    <>
      {salesSteerings.length === 0 ? (
        <ProgressSpinner />
      ) : (
        <div>
          <BusinessUnitDropdown selectedValue={selectedBusinessUnit} onChange={filterByBusinessUnit} />
          <DataTable
            paginator
            filters={filters}
            sortField="accommodationCode"
            sortOrder={1}
            rowsPerPageOptions={PAGINATOR_ROWS_PER_PAGE}
            rows={PAGINATOR_ROWS}
            value={salesSteerings}
            currentPageReportTemplate="Total: {totalRecords}"
            paginatorTemplate={PAGINATOR_TEMPLATE}
            rowExpansionTemplate={remarksExpansionTemplate}
            expandedRows={expandedRows}
            onRowToggle={(event) => setExpandedRows(event.data)}
            dataKey="id"
          >
            <Column expander style={{ width: '3em' }} />
            <Column field="accommodationCode" sortable filter header="Accommodation"></Column>
            <Column
              field="publicationCode"
              filterElement={(entry) => publicationFilterTemplate(entry, salesSteerings)}
              showFilterMatchModes={false}
              onFilterClear={() => setSelectedBusinessUnit(null)}
              onFilterApplyClick={() => setSelectedBusinessUnit(null)}
              sortable
              filter
              header="Publication"
            ></Column>
            <Column
              field="startDate"
              sortable
              filter
              dataType="date"
              header="Start Date"
              filterElement={dateFilterTemplate}
              body={(entry) => formatDate(entry.startDate)}
            ></Column>
            <Column
              field="endDate"
              sortable
              filter
              dataType="date"
              header="End Date"
              filterElement={dateFilterTemplate}
              body={(entry) => formatDate(entry.endDate)}
            ></Column>
            <Column field="contractId" sortable filter header="Contract Id"></Column>
            <Column field="transportHubTypeName" sortable filter header="Transport Type"></Column>
            <Column
              field="transportHubs"
              sortable
              filter
              header="Transport Hubs"
              body={(entry) => entry.transportHubs.join(', ')}
            ></Column>
            <Column
              field="createdDate"
              sortable
              filter
              dataType="date"
              header="Created Date"
              filterElement={dateFilterTemplate}
              body={(entry) => formatDateTime(entry.createdDate)}
            ></Column>
            <Column
              field="modifiedDate"
              sortable
              dataType="date"
              header="Modified Date"
              body={(entry) => formatDateTime(entry.modifiedDate)}
            ></Column>
          </DataTable>
        </div>
      )}
    </>
  );
}

export default SalesSteeringList;
